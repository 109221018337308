import { Navbar } from "flowbite-react";
import logo from "../../img/logo.png";

export default function Nav() {
  return (
    <Navbar fluid={true} rounded={true}>
      <Navbar.Brand to="/">
        <img
          src={logo}
          className="mr-3 h-6 sm:h-9"
          alt="Air@Work"
          width={"auto"}
          height={"auto"}
        />
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Navbar.Link href="/" active={true}>
          Soon
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
}
