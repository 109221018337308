import { Helmet } from "react-helmet-async";
import Soon from "../components/Soon";

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title>Air@Work</title>
        <meta
          name="description"
          content="Air@Work is a supplier of energy-efficient and environmentally friendly systems for ventilation, air filtration and climate control."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <Soon />
    </>
  );
}
