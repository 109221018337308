import logo from "../../img/logo.png";

export default function Soon() {
  return (
    <div className="p-8 md:p-12">
      <img
        className="w-full px-24 md:px-60"
        src={logo}
        alt="Air@Work"
        width={"auto"}
        height={"auto"}
      />
      <br />
      <h1 className="text-center text-xl font-bold mt-8 text-primary md:px-10 xl:px-56">
        Air@Work is a supplier of energy-efficient and environmentally friendly
        systems for ventilation, air filtration and climate control. With low
        investment and maintenance costs, non-residential buildings (schools,
        offices, etc.), data centers and industry are provided with sustainable
        air treatment.
      </h1>
      <br />
      <h2 className="text-center text-2xl font-extrabold mt-8 text-secondary xl:px-16">
        A healthy life starts with clean and fresh air!
      </h2>
      <br />
      <h3 className="text-center text-2xl font-bold mt-8 text-primary xl:px-16">
        Coming in 2023 to the North American market. <br />
        More information will be presented soon!! <br /> Contact:{" "}
        <a href="mailto:info@airatwork.ca" className=" hover:text-secondary">
          {" "}
          info@airatwork.ca
        </a>
      </h3>
    </div>
  );
}
